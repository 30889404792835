

// Import the functions you need from the SDKs you need
import { initializeApp } from 'https://www.gstatic.com/firebasejs/9.1.2/firebase-app.js'
import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.6.0/firebase-analytics.js";

//import { initializeApp } from "firebase/app";
//alert("xxx");


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAsrU3ArIOve2psvx6QP4DNbKBASNEIKnU",
  authDomain: "ether-tracker.firebaseapp.com",
  projectId: "ether-tracker",
  storageBucket: "ether-tracker.appspot.com",
  messagingSenderId: "1065200237956",
  appId: "1:1065200237956:web:24985c21635c5b0d0371d1",
  measurementId: "${config.measurementId}"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



